<template>
  <v-data-table :headers="headers" :items="desserts" sort-by="no" dense>
    <template v-slot:top>
      <v-toolbar flat>
        <v-btn color="success" dark rounded :to="{ name: 'Create Fixed Asset Edited' }">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ desserts.map(x => x.code).indexOf(item.code) + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Number",
        value: "symbol",
        sortable: false,
        divider: true,
        align: "end",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Date",
        value: "code",
        sortable: false,
        divider: true,
        align: "start",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Description",
        value: "country",
        sortable: false,
        divider: true,
        align: "end",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Fixed Asset",
        value: "country",
        sortable: false,
        divider: true,
        align: "start",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.desserts = [
        {
          symbol: "Rp",
          code: "IDR",
          country: "Indonesian Rupiah",
        },
        {
          symbol: "$",
          code: "USD",
          country: "US Dollar",
        },
        {
          symbol: "$",
          code: "SGD",
          country: "Singapore Dollar",
        },
      ];
    },
  },
};
</script>

<style></style>
